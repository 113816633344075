import * as React from 'react'
import {
  Pagination,
  ReferenceManyField,
  SelectField,
  FunctionField,
  DateField,
  DeleteButton,
  TextField,
} from 'react-admin'
import {SubscriptionStatusList} from 'src/types/enum/SubscriptionStatus'
import {SubscriptionCreateModal} from 'src/resources/company/subscription/SubscriptionCreateModal'
import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import EditListButtons from 'src/components/list/EditListButtons'
import {SubscriptionEditModal} from 'src/resources/company/subscription/SubscriptionEditModal'
import {useRecordContext} from 'ra-core'

interface TitleProps {
  record?: any;
}

export const CompanySubscriptionList = (props: any) => {
  const record = useRecordContext()

  return (
    <ReferenceManyField {...props} reference="subscription" target="companyId" addLabel={true}
                        sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>} perPage={50}>
      <RelatedList record={record} resource={'subscription'}   emptyTitle={'No subscriptions'}  bulkActionButtons={false} hasCreate={true}
                   actions={[<ModalCreateButton key={'create'} modal={<SubscriptionCreateModal mainRecord={record}/>}/>]}
                   createButton={<ModalCreateButton key={'create'} modal={<SubscriptionCreateModal mainRecord={record}/>}/>}>
        <TextField source={'id'} label={'ID'}/>
        <TextField source={'tariff.name'} label={'Tariff'}/>
        <SelectField source={'status'} label={'Status'} choices={SubscriptionStatusList}/>
        <DateField source={'endAt'} label={'Expired at'} showDate={true} showTime={true}/>
        <FunctionField source={'jobsLimit'} label={'Limits (J/M/E)'} render={(i: any) => [i.jobsUnlimited  ? '∞' : i.jobsLimit ?? i.tariff?.jobsLimit, i.managersUnlimited  ? '∞' : i.managersLimit ?? i.tariff?.managersLimit, i.evaluationUnlimited  ? '∞' :  i.evaluationLimit?? i.tariff?.evaluationLimit].join('/')}/>
        <EditListButtons editButton={<ModalEditButton label={''} modal={<SubscriptionEditModal mainRecord={record}/>}/>} deleteButton={<DeleteButton  redirect={false} label={''}/>}/>

      </RelatedList>
    </ReferenceManyField>)
}
