import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, TextField, DateField, SearchInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Company'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="name:$contL&referralCode:$contL"  variant={'outlined'} alwaysOn={true}  />

        </Filter>
    )
}

const CompanyList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Companies"

            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} hasCreate={false}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <TextField source={'name'} label={'Name'}/>
                <TextField source={'employeesCount'} label={'Employees count'}/>
                <TextField source={'referralCode'} label={'Referral Code'}/>
                <DateField source={'createdAt'} label={'Created'} showTime/>
            </Datagrid>
        </List>
    )
}

export default CompanyList
