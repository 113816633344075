import {AiCvRequestType} from 'src/types/enum/AiCvRequestType'

export enum CvEvaluationType {
  Detailed = 'detailed',
  Fast = 'fast',
}

export const CvEvaluationTypeList = [
  {id: AiCvRequestType.Detailed, name: 'Detailed'},
  {id: AiCvRequestType.Fast, name: 'Fast'}
]
