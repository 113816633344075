import ListIcon from '@mui/icons-material/List'
import TariffAddonList from 'src/resources/tariff-addon/TariffAddonList'
import TariffAddonEdit from 'src/resources/tariff-addon/TariffAddonEdit'
import TariffAddonCreate from 'src/resources/tariff-addon/TariffAddonCreate'

export default {
  create: TariffAddonCreate,
  edit: TariffAddonEdit,
  list: TariffAddonList,
  icon: ListIcon,
}