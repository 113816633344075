import * as React from 'react'
import {
  Pagination,
  ReferenceManyField,
  SelectField,
  DateField,
  TextField, ReferenceField,
} from 'react-admin'
import RelatedList from 'src/components/list/RelatedList'
import {useRecordContext} from 'ra-core'
import {SubscriptionAddonKindList} from 'src/types/enum/SubscriptionAddonKind'
import {SubscriptionTimeUnitList} from 'src/types/enum/SubscriptionTimeUnit'
import TransTextField from 'src/components/fields/TransTextField'

interface TitleProps {
  record?: any;
}

export const CompanySubscriptionAddonList = (props: any) => {
  const record = useRecordContext()

  return (
    <ReferenceManyField {...props} reference="subscription-addon" target="owner.companyId" addLabel={true}
                        sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>} perPage={50}>
      <RelatedList record={record} resource={'subscription-addon'}   emptyTitle={'No addons'}  bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'}/>
        <SelectField source={'kind'} label={'Kind'} choices={SubscriptionAddonKindList}/>
        <ReferenceField source="tariffId" reference="tariff-addon" label={'Tariff'}  sortable={false}>
          <TransTextField source={'name'} label={'Name'}  sortable={false}/>
        </ReferenceField>
        <TextField source={'period'} label={'Period H'}/>
        <SelectField source={'timeUnit'} label={'Time unit'} choices={SubscriptionTimeUnitList}/>
        <DateField source={'startAt'} label={'Start at'} showDate={true} showTime={true}/>
        <DateField source={'endAt'} label={'Expired at'} showDate={true} showTime={true}/>
      </RelatedList>
    </ReferenceManyField>)
}
