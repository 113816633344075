
export enum SubscriptionAddonKind {
  CandidatesMovement = 'candidatesMovement',
  AiCvEvaluationLimit = 'aiCvEvaluationLimit',
  VacancyLimit = 'vacancyLimit',
}

export const SubscriptionAddonKindList = [
  {id: SubscriptionAddonKind.CandidatesMovement, name: 'Candidates Movement'},
  {id: SubscriptionAddonKind.AiCvEvaluationLimit, name: 'Ai Cv Evaluation Limit'},
  {id: SubscriptionAddonKind.VacancyLimit, name: 'Vacancy Limit'}
]
