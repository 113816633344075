import * as React from 'react'
import {
  TabbedForm, NumberInput,
  BooleanInput,
  FormTab, SimpleFormIterator, SelectInput, required, TextInput, ArrayInput, FormDataConsumer,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {PurchaseTypeList} from 'src/types/enum/PurchaseType'
import {SubscriptionKindList} from 'src/types/enum/SubscriptionKind'
import SubscriptionTimeUnitPeriodInput from 'src/components/inputs/SubscriptionTimeUnitPeriodInput'
import {SubscriptionTimeUnit, SubscriptionTimeUnitList} from 'src/types/enum/SubscriptionTimeUnit'
const InfoTab = (props: any) => {
  const form = useFormContext()

  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <SelectInput source={'purchaseType'} label={'Type'} choices={PurchaseTypeList} variant={'outlined'} fullWidth={true} />
    <SelectInput source="kind" label={'Kind'} variant={'outlined'} choices={SubscriptionKindList} validate={required()} fullWidth={true}/>
    <TextInput source="shortName" label={'ShortName'} variant={'outlined'} fullWidth validate={required()} />
    <ArrayInput source="translations" label={'Content'}  validate={required()}>
      <SimpleFormIterator fullWidth>
        <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={[{id: 'en', name: 'EN'}, {id: 'id', name: 'Bahasa'}]} validate={required()} />
        <TextInput source="name" label={'Name'} variant={'outlined'} fullWidth validate={required()} />

        <TextInput source="description" label={'Description'} multiline variant={'outlined'} fullWidth  />
      </SimpleFormIterator>
    </ArrayInput>

    <ArrayInput source="priceByPeriod" label={'Prices by periods'} >
      <SimpleFormIterator fullWidth disableReordering={true} inline >
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData, getSource, ...rest }) =>
              (<SubscriptionTimeUnitPeriodInput source={getSource('period')}  label={'Period'}  timeUnit={SubscriptionTimeUnit.Month} variant={'outlined'} fullWidth={true} {...rest} />)
          }
        </FormDataConsumer>
        <NumberInput source="price" label={'Price'} helperText={false}  variant={'outlined'} fullWidth={false}  />
      </SimpleFormIterator>
    </ArrayInput>

    <BooleanInput source="managersUnlimited" label={'Managers Unlimited'} variant={'outlined'} fullWidth  />
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => !formData.managersUnlimited  && (
          <NumberInput source="managersLimit" label={'Managers Limit'} variant={'outlined'} fullWidth  />
      )}
    </FormDataConsumer>

    <BooleanInput source="jobsUnlimited" label={'Jobs Unlimited'} variant={'outlined'} fullWidth  />
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => !formData.jobsUnlimited && (
          <NumberInput source="jobsLimit" label={'Jobs Limit'} variant={'outlined'} fullWidth  />
      )}
    </FormDataConsumer>

    <BooleanInput source="evaluationUnlimited" label={'Evaluations Unlimited'} variant={'outlined'} fullWidth  />
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => !formData.evaluationUnlimited  && (
          <NumberInput source="evaluationLimit" label={'Evaluations Limit'} variant={'outlined'} fullWidth  />
      )}
    </FormDataConsumer>
   </FormTab>)
}
const TariffForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default TariffForm
