export enum SubscriptionKind {
  Professional = 'companyProfessional',
  Enterprise = 'companyEnterprise',
  Base = 'companyBase',
}
export const SubscriptionKindList = [
  {id: SubscriptionKind.Base, name: 'Base'},
  {id: SubscriptionKind.Professional, name: 'Professional'},
  {id: SubscriptionKind.Enterprise, name: 'Enterprise'},
]
