import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import TariffAddonForm from 'src/resources/tariff-addon/TariffAddonForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Tariff Addon</span>

const TariffAddonCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <TariffAddonForm/>
    </Create>
  )
}

export default TariffAddonCreate